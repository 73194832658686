import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cultural-events',
  templateUrl: './cultural-events.component.html',
  styleUrls: ['./cultural-events.component.css']
})
export class CulturalEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
