import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule, Routes, ActivatedRoute, ParamMap  } from '@angular/router';
import { NurseryComponent } from './nursery/nursery.component';
import { PreNurseryComponent } from './pre-nursery/pre-nursery.component';
import { PrimaryComponent } from './primary/primary.component';
import { TheSchoolComponent } from './the-school/the-school.component';
import { BannerComponent } from './banner/banner.component';
import { AssessmentsComponent } from './nursery/assessments/assessments.component';
import { CompetitionsComponent } from './nursery/competitions/competitions.component';
import { CulturalEventsComponent } from './nursery/cultural-events/cultural-events.component';
import { DayAtSchoolComponent } from './nursery/day-at-school/day-at-school.component';
import { OurPhilosophyComponent } from './nursery/our-philosophy/our-philosophy.component';
import { TechnologyComponent } from './nursery/technology/technology.component';
import { NurseryActivitiesComponent  } from './nursery/nursery-activities/nursery-activities.component';
import { ActivitiesComponent } from './pre-nursery/activities/activities.component';
import { PhysicalDevelopmentComponent } from './pre-nursery/physical-development/physical-development.component';
import { AcademicsComponent } from './primary/academics/academics.component';
import { ExtraCurricularActivitiesComponent } from './primary/extra-curricular-activities/extra-curricular-activities.component';
import { HolisticDevelopmentComponent } from './primary/holistic-development/holistic-development.component';
import { InfrastructureComponent } from './primary/infrastructure/infrastructure.component';
import { AchievementsComponent } from './the-school/achievements/achievements.component';
import { NewsComponent } from './the-school/news/news.component';
import { SchoolScheduleComponent } from './the-school/school-schedule/school-schedule.component';
import { TrainTheTeacherComponent } from './the-school/train-the-teacher/train-the-teacher.component';
import { TeachingFraternityComponent } from './the-school/teaching-fraternity/teaching-fraternity.component';
import { ContactComponent } from './contact/contact.component';
import { AppServiceService } from './app-service.service';
import { PhilosophyComponent } from './pre-nursery/philosophy/philosophy.component';
import { CompetitionComponent } from './primary/competition/competition.component';
import { PreNurCulturalComponent } from './pre-nursery/pre-nur-cultural/pre-nur-cultural.component';
import { YogaDayComponent } from './primary/yoga-day/yoga-day.component';
import { GalleryComponent } from './gallery/gallery.component';
import { VideosComponent } from './videos/videos.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/about',
    pathMatch: 'full'
  },
 { path:'about',component:AboutComponent},
 {path:'school',component:TheSchoolComponent},
 {path:'preNursery',component:PreNurseryComponent},
 {path:'nursery',component:NurseryComponent},
 {path:'primary',component:PrimaryComponent},
 {path:'contact',component:ContactComponent},
 {path:'school/teachingFraternity',component:TeachingFraternityComponent},
 {path:'school/schoolSchedule',component:SchoolScheduleComponent},
 {path:'school/trainTheTeacher',component:TrainTheTeacherComponent},
{path:'school/achievements',component:AchievementsComponent},
{path:'school/news',component:NewsComponent},
{path:'preNursery/ourPhilosophy',component:PhilosophyComponent},
{path:'preNursery/activities',component:ActivitiesComponent},
{path:'preNursery/physicalDevelopment',component:PhysicalDevelopmentComponent},
{path:'preNursery/culturalEvents',component:PreNurCulturalComponent},
{path:'nursery/ourPhilosophy',component:OurPhilosophyComponent},
{path:'nursery/dayAtSchool',component:DayAtSchoolComponent},
{path:'nursery/culturalEvents',component:CulturalEventsComponent},
{path:'nursery/competitions',component:CompetitionsComponent},
{path:'nursery/technology',component:TechnologyComponent},
{path:'nursery/assessments',component:AssessmentsComponent},
{path:'nursery/nurseryActivities',component:NurseryActivitiesComponent},
{path:'primary/academics',component:AcademicsComponent},
{path:'primary/infrastructure',component:InfrastructureComponent},
{path:'primary/extraCurricularActivities',component:ExtraCurricularActivitiesComponent},
{path:'primary/competitions',component:CompetitionComponent},
{path:'primary/holisticDevelopment',component:HolisticDevelopmentComponent},
{path:'primary/assessments',component:AssessmentsComponent},
{path:'primary/yogaDay',component:YogaDayComponent},
{path:'school/news/:newsid',component:NewsComponent},
{path:'gallery',component:GalleryComponent},
{path:'videos',component:VideosComponent}
];
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HeaderComponent,
    NurseryComponent,
    PreNurseryComponent,
    ContactComponent,
    PrimaryComponent,
    TheSchoolComponent,
    BannerComponent,
    AssessmentsComponent,
    CompetitionsComponent,
    CulturalEventsComponent,
    DayAtSchoolComponent,
    OurPhilosophyComponent,
    TechnologyComponent,
    ActivitiesComponent,
    PhysicalDevelopmentComponent,
    AcademicsComponent,
    ExtraCurricularActivitiesComponent,
    HolisticDevelopmentComponent,
    InfrastructureComponent,
    AchievementsComponent,
    NewsComponent,
    SchoolScheduleComponent,
    TrainTheTeacherComponent,
    TeachingFraternityComponent,
    PhilosophyComponent,
    CompetitionComponent,
    PreNurCulturalComponent,
    NurseryActivitiesComponent,
    YogaDayComponent,
    GalleryComponent,
    VideosComponent,
   
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [AppServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
