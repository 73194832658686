import { Component, OnInit,EventEmitter, Output, Input } from '@angular/core';
import {Router} from '@angular/router';
import {AppServiceService} from '../app-service.service';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent implements OnInit {
  
  public news= []
  newsid: any;
  constructor(private router:Router,private service:AppServiceService) { }
 
  ngOnInit() { 
    this.news=this.service.getnews();
  }
    gotoNews(newsid){
      this.service.selectedNewsId=newsid;
    }
}
