import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nursery',
  templateUrl: './nursery.component.html',
  styleUrls: ['./nursery.component.css']
})
export class NurseryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
