import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-the-school',
  templateUrl: './the-school.component.html',
  styleUrls: ['./the-school.component.css']
})
export class TheSchoolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
