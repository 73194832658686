import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yoga-day',
  templateUrl: './yoga-day.component.html',
  styleUrls: ['./yoga-day.component.css']
})
export class YogaDayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
