import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {AppServiceService} from '../../app-service.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
 
public news=[];
public selectedNewsId = this.service.selectedNewsId;
  constructor(private router:Router,private service:AppServiceService) { }
  ngOnInit() {
    this.news=this.service.getnews();
  }
  public scroll(element: any) {
    element.scrollIntoView({ behavior: 'smooth' });
    
}
}
