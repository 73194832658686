import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-train-the-teacher',
  templateUrl: './train-the-teacher.component.html',
  styleUrls: ['./train-the-teacher.component.css']
})
export class TrainTheTeacherComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
