import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-nur-cultural',
  templateUrl: './pre-nur-cultural.component.html',
  styleUrls: ['./pre-nur-cultural.component.css']
})
export class PreNurCulturalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
