import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-nursery',
  templateUrl: './pre-nursery.component.html',
  styleUrls: ['./pre-nursery.component.css']
})
export class PreNurseryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
