import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-at-school',
  templateUrl: './day-at-school.component.html',
  styleUrls: ['./day-at-school.component.css']
})
export class DayAtSchoolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
